import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import styled from "@emotion/styled";
import tw from "twin.macro";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const StyledForm = styled.div`
  [type="email"],
  [type="number"],
  [type="tel"],
  [type="text"],
  select,
  textarea {
    ${tw`w-full px-4 py-4 border border-gray-200 bg-white rounded-lg focus:border-transparent focus:outline-none focus:ring-3 focus:ring-secondary-500/30 transition-all duration-300 ease-linear`}
  }
`;

const Page = ({ data }) => {

  const [state, setState] = useState({});

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (
        localStorage.getItem("contact-owner") &&
        state["contact-owner"] == null
      ) {
        setState((state) => ({
          ...state,
          "contact-owner": localStorage.getItem("contact-owner"),
        }));
      } else if (state["contact-owner"] == null) {
        setState((state) => ({
          ...state,
          "contact-owner": 41454685,
        }));
      }
    }
  }, [state]);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () =>
          (document.getElementById(
            "private-wealth-form-ajax-response"
          ).innerHTML =
            `<p className="text-bold">Thank you for for submission! We will get in touch with you shortly.</p>
            <p>Click <a href="/process/">here</a> to learn what to expect next</p>`),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "privateWealthForm",
        })
      )
      .catch((error) => alert(error));
  };
  return (
    <Layout>
      <SearchEngineOptimization
        title="Schedule Consult | Dark Horse Private Wealth"
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-[#F7F5EF] pt-16 pb-24 md:pt-22 md:pb-40 zigZagBg">
        <div className="container">
        <StyledForm>
      <div
        id="private-wealth-form-ajax-response"
        className="text-center"
      ></div>
      <form
        name="Private Wealth Form"
        method="post"
        action=""
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Quote - Fractional CFO" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>
        <div className="mx-auto grid group rounded-4xl bg-white px-4 py-6 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:p-[46px] md:max-w-[750px]">
          <header className="mx-auto mb-14 text-center md:mb-20 md:max-w-[640px]">
            <h1>Book a Consult</h1>
          </header>
         
      <input type="hidden" name="Private-Wealth-Form" value="Private Wealth Form" />

      <div className="mb-6">
          <fieldset>
            <legend className="mb-1 block font-semibold text-primary-900">
            Are you currently a client of Dark Horse CPAs?
            </legend>
            <div className="mt-2 grid grid-cols-1 gap-y-3.5 gap-x-6">
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="radio"
                  name="are-you-currently-working-with-darkhorse"
                  value="Yes"
                  onChange={handleChange}
                  required
                />
                Yes
                <span className="radio-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="radio"
                  name="are-you-currently-working-with-darkhorse"
                  onChange={handleChange}
                  value="No"
                />
                No
                <span className="radio-custom"></span>
              </label>
            </div>
          </fieldset>
        </div>

        <div className="mb-6">
          <fieldset>
            <legend className="mb-1 block font-semibold text-primary-900">
            What services are you interested in discussing?
            </legend>
            <div className="mt-2 grid grid-cols-1 gap-y-3.5 gap-x-6">
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="checkbox"
                  name="what-specific-services-would-you-like-handled-by-a-fractional-cfo"
                  value="General Financial Planning"
                  onChange={handleChange}
                />
                General Financial Planning
                <span className="checkbox-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="checkbox"
                  name="what-specific-services-would-you-like-handled-by-a-fractional-cfo"
                  onChange={handleChange}
                  value="Retirement Plans"
                />
                Retirement Plans
                <span className="checkbox-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="checkbox"
                  name="what-specific-services-would-you-like-handled-by-a-fractional-cfo"
                  value="Real Estate, 1031 Exchange"
                  onChange={handleChange}
                />
                Real Estate, 1031 Exchange
                <span className="checkbox-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="checkbox"
                  name="what-specific-services-would-you-like-handled-by-a-fractional-cfo"
                  value="Tax Deferral/Reduction Strategies"
                  onChange={handleChange}
                />
                Tax Deferral/Reduction Strategies
                <span className="checkbox-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="checkbox"
                  name="what-specific-services-would-you-like-handled-by-a-fractional-cfo"
                  value="Investment Management"
                  onChange={handleChange}
                />
                Investment Management
                <span className="checkbox-custom"></span>
              </label>
            </div>
          </fieldset>
        </div>

          
          <div className="mb-6">
          <fieldset>
            <legend className="mb-1 block font-semibold text-primary-900">
            Are you currently working with a Financial Planner/Wealth Advisor?
            </legend>
            <div className="mt-2 grid grid-cols-1 gap-y-3.5 gap-x-6">
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="radio"
                  name="are-you-currently-working-with-a-wealth-advisor"
                  value="Yes"
                  onChange={handleChange}
                  required
                />
                Yes
                <span className="radio-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="radio"
                  name="are-you-currently-working-with-a-wealth-advisor"
                  onChange={handleChange}
                  value="No"
                />
                No
                <span className="radio-custom"></span>
              </label>
              <label className="relative cursor-pointer pl-8 text-sm">
                <input
                  type="radio"
                  name="are-you-currently-working-with-a-wealth-advisor"
                  onChange={handleChange}
                  value="No"
                />
                It's Complicated
                <span className="radio-custom"></span>
              </label>
            </div>
          </fieldset>
        </div>
     
          <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="comments"
          >
            Anything else you would like to add?
          </label>
          <textarea name="comments"  rows="4" onChange={handleChange}/>
        </div>

        <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="first-name"
          >
            First Name
          </label>
          <input
              type="text"
              name="first-name"
              onChange={handleChange}
              required={true}
            />
        </div>
        <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="last-name"
          >
            Last Name
          </label>
          <input
              type="text"
              name="last-name"
              onChange={handleChange}
              required={true}
            />
        </div>

        <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
              type="tel"
              name="phone"
              onChange={handleChange}
              required={true}
            />
        </div>

        <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="email"
          >
            Email
          </label>
          <input
              type="email"
              name="email"
              onChange={handleChange}
              required={true}
            />
        </div>

          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="state"
            >
              State
            </label>
            <select
              name="state"
              className="w-full rounded-lg border border-gray-200 bg-white px-4 py-4 transition-all duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-secondary-500/30"
              onChange={handleChange}
              required={true}
            >
              <option value="">Select One...</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
       

        

        

        

        <ButtonSolid type="submit" text="Send message" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} />
    </div>   
    </form>
    
          
          </StyledForm>
          
        </div>
        
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    taxPlanning: file(relativePath: { eq: "client-login/dh-personal.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    intuit: file(relativePath: { eq: "client-login/schwab.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    quickbooks: file(relativePath: { eq: "client-login/quickbooks.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    reporting: file(relativePath: { eq: "client-login/401k.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
  }
`;

export default Page;
